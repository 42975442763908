import { useEffect, useState } from 'react'
import config from './config'

export default function useFetch(url) {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function loadData() {
      setLoading(true)
      const response = await fetch(url, {
        headers: {
          //sAuthorization: 'Basic ' + config.woocommerceKey,
        },
      })
      if (!response.ok) {
        // oups! something went wrong
        return
      }
      setLoading(false)
      const posts = await response.json()
      setData(posts)
    }

    loadData()
  }, [url])
  return { data, loading }
}
