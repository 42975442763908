import React from 'react'
import { render } from 'react-dom'
import { Route, Routes, useMatch, BrowserRouter as Router } from 'react-router-dom'

import App from './App'

import './index.scss'

// Disable all log statements in production builds
if (process.env.NODE_ENV !== 'development') console.log = function () {}

const Main = () => (
  <Router location={location}>
    <Routes>
      <Route path="/*" element={<App />} />
    </Routes>
  </Router>
)

const startApp = async () => {
  return render(<Main />, document.getElementById('root'))
}
startApp()
