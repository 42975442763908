import React, { useState, useEffect } from 'react'
import styles from './Sidebar.module.scss'
import { Drawer, Button } from '@blueprintjs/core'

import styles2 from './App.module.scss'
import ContactForm from 'ContactForm'
import { NavLink, useNavigate, Route, Routes, useMatch, BrowserRouter as Router } from 'react-router-dom'
import Title from './Title'

const SidebarCollection = (props) => {
  const navigate = useNavigate()
  const nav = (link) => {
    props.setIsOpen(false)
    navigate(link)
  }
  const match = useMatch('/:page/*')

  const page = match?.params?.page
  const id = match?.params?.id
  return (
    <Drawer isOpen={props.open} size={'80%'}>
      <div>
        <div style={{ display: 'flex', margin: 0 }}>
          <span style={{ flex: 1, margin: 15, marginRight: 0 }}>
            <div style={{ fontSize: 30, letterSpacing: 2 }}>
              <div>categories</div>
            </div>
          </span>
          <Button
            minimal
            icon="cross"
            large
            onClick={() => props.setIsOpen(false)}
            style={{ flex: 0, marginRight: 15, marginTop: 20, alignSelf: 'start' }}
          />
        </div>
        <div style={{ margin: 15, fontSize: 13 }} onClick={() => props.setIsOpen(false)}>
          {props.categories?.map((d) => (
            <NavLink
              key={d.slug}
              style={{ pointerEvents: d.count === 0 ? 'none' : 'undefined' }}
              to={'/collection/' + d.slug.toLowerCase()}
              activeClassName={styles2.activeLink}
              className={styles2.link}>
              {d.parent > 0 && <>&nbsp;&nbsp;&nbsp;&nbsp;</>}
              {d.name}
            </NavLink>
          ))}
        </div>
      </div>
    </Drawer>
  )
}

export default SidebarCollection
