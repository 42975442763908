import React, { useState } from 'react'
import Title from './Title'

import Next from './Next'
import Footer from './Footer'
import { useNavigate, NavLink } from 'react-router-dom'
const ServiceEducation = () => {
  document.title = 'Consign your exclusive Fossils & Artefacts - Willendorf Gallery Services'
  document.querySelector('meta[name="description"]').setAttribute(
    'content',
    `Willendorf Gallery offers the possibility to consign your items to our collection and find you the right
    customer via a worldwide network of dealers, clients and museums. A detailed description and clear provenance
    with professional pictures is quintessential to this consignment and will only be validated after a diligent
    investigation on our part.`
  )

  const navigate = useNavigate()
  return (
    <>
      <Title>Consign exclusive fossils</Title>
      <p>
        Do you own fossils or artefacts but don’t know what to do with them? Have you inherited some interesting pieces
        or an entire collection?
      </p>
      <p>
        Willendorf Gallery offers the possibility to consign your items to our collection and find you the right
        customer via a worldwide network of dealers, clients and museums. A detailed description and clear provenance
        with professional pictures is quintessential to this consignment and will only be validated after a diligent
        investigation on our part.
      </p>
      <p>
        For more information see <NavLink to="/about/terms">Terms and Conditions</NavLink>.
      </p>
      <Next to="../education" text="Education" />

      <Footer />
    </>
  )
}

export default ServiceEducation
