import React, { useState } from 'react'
import styles from './Contact.module.scss'
import Title from './Title'
import ReCAPTCHA from 'react-google-recaptcha'
import { Button, Spinner, Icon } from '@blueprintjs/core'
import { Email } from '@material-ui/icons'
const recaptchaRef = React.createRef()
const ContactForm = (props) => {
  const [invalidEmail, setInvalidEmail] = useState(false)
  const [invalidName, setInvalidName] = useState(false)
  const [formSent, setFormSent] = useState(false)
  const [disabled, setDisabled] = useState(false)
  return (
    <span>
      {!formSent && (
        <>
          <div style={{ marginTop: 40 }}>
            {invalidName && (
              <span style={{ textAlign: 'right', display: 'block', color: 'red' }}>Please fill in your name</span>
            )}

            <span className={styles.field}>
              <input id="itemname" hidden value={props?.data?.name} />
              <input id="sku" hidden value={props?.data?.sku} />
              <input id="location" hidden value={window.location} />
              <input
                id="pname"
                placeholder="your name"
                style={{ width: '100%', borderColor: invalidName ? 'red' : '#aaa' }}
              />
            </span>
          </div>
          <div>
            {invalidEmail && (
              <span style={{ textAlign: 'right', display: 'block', color: 'red' }}>
                Please use a valid email address
              </span>
            )}
            <span className={styles.field}>
              <input
                type="email"
                required
                id="emailaddress"
                placeholder="your email"
                style={{ width: '100%', borderColor: invalidEmail ? 'red' : '#aaa' }}
              />
            </span>
          </div>
          <div>
            <span className={styles.field}>
              <textarea id="message" placeholder="your message" style={{ height: 268 }} />
            </span>
          </div>
          <div>
            <button
              icon="send-message"
              disabled={disabled}
              className={[styles.button, 'bp3-dark'].join(' ')}
              data-sitekey="6LfFdWkdAAAAAAnykhIyn7UX8D9slw1uoQtAWYw4"
              data-action="submit"
              onClick={async () => {
                setDisabled(true)
                setInvalidEmail(false)
                setInvalidName(false)
                try {
                  const ValidateEmail = (mail) => {
                    console.log('maluk' + mail)
                    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
                      return true
                    }
                    setInvalidEmail(true)
                    return false
                  }
                  const ValidateName = (str) => {
                    console.log('dd' + str)
                    if (str) {
                      return true
                    }
                    setInvalidName(true)
                    return false
                  }
                  const name = document.getElementById('pname').value
                  const email = document.getElementById('emailaddress').value
                  const message = document.getElementById('message').value
                  const location = document.getElementById('location').value
                  const item = document.getElementById('itemname').value
                  const sku = document.getElementById('sku').value
                  const a = !ValidateEmail(email)
                  const b = !ValidateName(name)
                  if (a || b) {
                    setDisabled(false)
                    return
                  }
                  const token = await recaptchaRef.current.executeAsync()

                  var formData = new FormData()

                  formData.append('pname', name)
                  formData.append('sku', sku)
                  formData.append('emailaddress', email)
                  formData.append('message', message)
                  formData.append('location', location)
                  formData.append('itemname', item)

                  const validate = () => {}

                  const result = await fetch(
                    'https://api.willendorfgallery.com/wp-json/contact-form-7/v1/contact-forms/71/feedback',
                    {
                      method: 'POST',
                      body: formData,
                    }
                  )
                  setDisabled(false)
                  setFormSent(true)
                } catch (err) {
                  setDisabled(false)
                  console.log(err)
                  return
                }
              }}
              style={{ marginTop: 20, float: 'right', width: 200 }}>
              {disabled ? <Spinner active size={18} /> : <Icon icon="send-message" />}
              <span style={{ marginLeft: 10 }}>{disabled ? 'Sending...' : 'Send'}</span>
            </button>
            <ReCAPTCHA size="invisible" ref={recaptchaRef} sitekey="6LfFdWkdAAAAAAnykhIyn7UX8D9slw1uoQtAWYw4" />,
          </div>
        </>
      )}
      {formSent && <div style={{ marginTop: 40 }}>Thanks for sending</div>}
    </span>
  )
}

export default ContactForm
