import React, { useState } from 'react'
import Footer from './Footer'
import styles from './Contact.module.scss'
import styles2 from './App.module.scss'
import ContactForm from 'ContactForm'
import Venus from './images/venus2.svg'
import { Redirect, useNavigate, Route, Routes, useMatch, BrowserRouter as Router } from 'react-router-dom'
import Title from './Title'
const Contact = () => {
  document.title = 'Get in contact with Willendorf Gallery'
  document
    .querySelector('meta[name="description"]')
    .setAttribute(
      'content',
      'Have an interest or question about a particular item or service. Do not hesitate to reach out!'
    )

  const navigate = useNavigate()
  return (
    <div className={styles2.wrapper}>
      <div className={styles2.columns}>
        <ContactForm />

        <span style={{ fontSize: 14, lineHeight: 1.3 }}>
          <Title>Contact</Title>
          <h3>EMAIL &amp; PHONE</h3>
          <div style={{ lineHeight: 1.5, letterSpacing: 1 }}>INFO@WILLENDORFGALLERY.COM</div>
          <div style={{ lineHeight: 1.5, letterSpacing: 1 }}>+32 479 03 03 61</div>
          <div style={{ lineHeight: 1.5, letterSpacing: 1 }}>+32 474 89 21 54</div>
          <h3 style={{ marginTop: 30 }}>INSTAGRAM</h3>
          <div style={{ lineHeight: 1.5, letterSpacing: 1 }}>@WILLENDORFGALLERY</div>
          <h3 style={{ marginTop: 30 }}>ADDRESS</h3>
          <div style={{ lineHeight: 1.5, letterSpacing: 1 }}>MOONSSTRAAT 54</div>
          <div style={{ lineHeight: 1.5, letterSpacing: 1 }}>2018 ANTWERP</div>
          <div style={{ lineHeight: 1.5, letterSpacing: 1 }}>BELGIUM</div>
        </span>
      </div>{' '}
      <Footer />
    </div>
  )
}

export default Contact
