import React, { useState, useRef } from 'react'

import Footer from './Footer'
import { Navigation, EffectFade, Pagination, Thumbs, Zoom, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import {
  WhatsappShareButton,
  WhatsappIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterIcon,
  TwitterShareButton,
  EmailShareButton,
  EmailIcon,
} from 'react-share'

import { Icon } from '@blueprintjs/core'
import { useNavigate, useLocation, useMatch } from 'react-router-dom'
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'
import InnerImageZoom from 'react-inner-image-zoom'
import ContactForm from './ContactForm'
import { Button } from '@blueprintjs/core'
import styles from './Products.module.scss'
import styles2 from './App.module.scss'
import config from './config'

const displayOrder = (a, b) => {
  switch (a.key) {
    case 'period':
      return -1
  }
  return 1
}

const Product = (props) => {
  const [selectedImage, setSelectedImage] = useState(0)
  const swiperRef = useRef()
  const [enquirePressed, setEnquirePressed] = useState(false)

  const currentId = props.currentId
  const [detail, setDetail] = useState(false)
  const { data, prev, next, filter, type } = props
  document.title = data.name + ' - Willendorf Gallery'
  document.querySelector('meta[name="description"]').setAttribute('content', data.description)
  console.log(data)
  let navigate = useNavigate()
  let url = 'https://github.com'

  return (
    <div className={styles2.wrapper}>
      <div className={styles.p}>
        <div className={styles2.mobile} style={{ display: 'flex' }}>
          <span style={{ flex: 1 }}>
            <a onClick={() => navigate(`/${type}/${filter}`)} style={{ fontSize: 13 }}>
              <Icon icon="arrow-left" style={{ marginRight: 10 }} />
              BACK TO OVERVIEW
            </a>
            <h2>{data.name}</h2>
            <div style={{ width: 60, height: 2, backgroundColor: 'black', marginBottom: 20 }}></div>
          </span>
        </div>
        {!enquirePressed && (
          <>
            <div className={[styles2.desktop, styles.desktopSpacer].join(' ')}>
              {data.images.length > 1 && (
                <span className={styles.thumbs_container}>
                  {data.images.map((image, index) => (
                    <div
                      onClick={() => {
                        setSelectedImage(index)
                        swiperRef.current.swiper.slideTo(index)
                      }}
                      className={styles.thumb}
                      key={index}>
                      <img
                        src={image.woocommerce_thumbnail}
                        width="150"
                        className={selectedImage === index ? styles.active_thumb : undefined}
                      />
                    </div>
                  ))}
                </span>
              )}
            </div>

            <div className={[styles.swiperWrapper, styles.desktopSpacer].join(' ')}>
              <Swiper
                modules={[Navigation, Pagination, Thumbs, EffectFade]}
                onActiveIndexChange={(swiper) => setSelectedImage(swiper.activeIndex)}
                pagination={true}
                clickable={false}
                className={styles.swiper}
                ref={swiperRef}
                slidesPerView={1}
                style={{ userSelect: 'none', border: '1px solid black', lineHeight: 0 }}
                initialSlide={selectedImage}
                centeredSlides={true}>
                {data?.images?.map((img, index) => (
                  <SwiperSlide key={index}>
                    <InnerImageZoom src={img.woocommerce_single} zoomSrc={img.src} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </>
        )}
        {enquirePressed && (
          <div style={{ width: 591, height: 490, marginTop: 50 }}>
            <ContactForm data={data} />
          </div>
        )}
        <span className={styles.short_summary} style={{ display: 'flex' }}>
          <div className={styles2.desktop}>
            <a onClick={() => navigate(`/${type}/${filter}`)} style={{ fontSize: 13 }}>
              <Icon icon="arrow-left" style={{ marginRight: 10 }} />
              BACK TO OVERVIEW
            </a>
            <h2>{data.name}</h2>
            <div style={{ width: 60, height: 2, backgroundColor: 'black', marginBottom: 20 }}></div>
          </div>
          <h3 className={styles2.mobile}>Description</h3>

          <div style={{ flex: 1, letterSpacing: 0.5 }}>
            <span dangerouslySetInnerHTML={{ __html: data.description }} />
            <span style={{ width: '100%', display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
              <span style={{ fontSize: 11, color: '#888' }}></span>
              <EmailShareButton
                url={document.location}
                subject={data.name}
                body={data.description.replace(/(<([^>]+)>)/gi, '')}
                title={data.name}
                size={32}
                round>
                <EmailIcon round={true} size={24} />
              </EmailShareButton>
              <TwitterShareButton
                url={document.location}
                title={data.name}
                hashtags={data.meta_data.filter((d) => ['period', 'Provenance'].includes(d.key)).map((d) => d.value)}
                size={32}
                round>
                <TwitterIcon round={true} size={24} />
              </TwitterShareButton>
              <WhatsappShareButton
                separator=" - "
                url={document.location}
                title={data.name + ': ' + data.description.replace(/(<([^>]+)>)/gi, '')}
                size={32}
                round>
                <WhatsappIcon round={true} size={24} />
              </WhatsappShareButton>
              <FacebookShareButton
                url={document.location}
                title={data.name}
                size={32}
                round
                quote={data.description.replace(/(<([^>]+)>)/gi, '')}
                hashtags={data.meta_data.filter((d) => ['period', 'Provenance'].includes(d.key)).map((d) => d.value)}>
                <FacebookIcon round={true} size={24} />
              </FacebookShareButton>
            </span>
          </div>

          <div className={styles.description} style={{ marginTop: 30 }}>
            {data.meta_data
              .filter((data) => data.key[0] !== '_')
              .sort(displayOrder)
              .map((data, index) => (
                <div key={index} className={styles.field}>
                  <span className={styles.label}>{data.key}</span>
                  <span className={styles.value}>{data.value}</span>
                </div>
              ))}
            <div className={styles.field}>
              <span className={styles.label}>Size</span>
              <span className={styles.value}>
                {data.dimensions.length} <span style={{ fontSize: 9 }}>x</span> {data.dimensions.width}{' '}
                <span style={{ fontSize: 9 }}>x</span> {data.dimensions.height} <span style={{ fontSize: 9 }}>cm</span>
              </span>
            </div>
            <div className={styles.field}>
              <span className={styles.label}>Weight</span>
              <span className={styles.value}>
                {data.weight} <span style={{ fontSize: 9 }}>kg</span>
              </span>
            </div>
            {!data.categories.map((d) => d.slug.toLowerCase()).includes('archive') && (
              <div className={styles.field}>
                <span className={styles.label}>REF</span>
                <span className={styles.value}>{data.sku}</span>
              </div>
            )}
            {!data.categories.map((d) => d.slug.toLowerCase()).includes('archive') && (
              <div className={styles.field}>
                <span className={styles.label}>Price</span>
                <span className={styles.value}>
                  {data.stock_status === 'outofstock' && <>SOLD</>}
                  {data.stock_status !== 'outofstock' && (
                    <>
                      {data.sale_price ? (
                        <>
                          <span style={{ textDecoration: 'line-through' }}>&nbsp;{data.regular_price} EUR&nbsp;</span>
                          <Icon
                            icon="arrow-right"
                            style={{ marginLeft: 5, position: 'relative', top: 2, color: '#888' }}
                          />{' '}
                          {data.sale_price} EUR
                        </>
                      ) : data.regular_price ? (
                        <>{data.regular_price} EUR </>
                      ) : (
                        'P.O.R'
                      )}
                    </>
                  )}
                </span>
              </div>
            )}
          </div>
          <div style={{ width: '100%' }} className={styles2.desktop}>
            <button
              onClick={() => setEnquirePressed(!enquirePressed)}
              type="submit"
              style={{
                border: 'none',
                marginTop: 20,
                width: '100%',
                height: 40,
                float: 'right',
              }}>
              {enquirePressed ? 'View photos' : 'Request more info'}
            </button>
          </div>
          <div style={{ width: '100%' }}></div>
        </span>
        <div className={styles2.mobile} style={{ marginTop: 30 }}>
          <h3>Contact us about this item</h3>
          <ContactForm data={data} />
        </div>
      </div>

      {prev !== null && (
        <span
          onClick={() => navigate(`/${type}/${filter}/${prev.id}`)}
          id="swiper-button-prev"
          className={[styles.navigationButton, styles2.widescreen].join(' ')}
          style={{ position: 'absolute', bottom: 30, left: 30 }}>
          <svg width="48" height="48" style={{ verticalAlign: 'middle', marginRight: 10 }}>
            <g transform="scale(2)">
              <path d="M2.117 12l7.527 6.235-.644.765-9-7.521 9-7.479.645.764-7.529 6.236h21.884v1h-21.883z" />
            </g>
          </svg>
          <div style={{ fontSize: 13, marginTop: 0, color: '#aaa' }}>PREV</div>
          <div>{prev.name}</div>
          {false && <img src={prev.images[0].woocommerce_thumbnail} style={{ width: 150, border: '1px solid #ccc' }} />}
        </span>
      )}

      {next !== null && (
        <span
          onClick={() => navigate(`/${type}/${filter}/${next.id}`)}
          id="swiper-button-next"
          className={[styles.navigationButton, styles2.widescreen].join(' ')}
          style={{ position: 'absolute', bottom: 30, right: 30, textAlign: 'right' }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            style={{ verticalAlign: 'middle', marginLeft: 10 }}>
            <g transform="scale(2)">
              <path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
            </g>
          </svg>
          <div style={{ fontSize: 13, marginTop: 0, color: '#aaa' }}>Next</div>
          <div>{next.name}</div>
          {false && <img src={next.images[0].src} style={{ width: 150, border: '1px solid #ccc' }} />}
        </span>
      )}

      {detail && (
        <div
          style={{
            backgroundColor: '#000000cc',

            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 100,
            overflow: 'scroll',
          }}>
          <img src={data.images[selectedImage].src} />
          <div
            style={{
              position: 'fixed',
              top: 30,
              margin: 'auto',
              left: 0,
              right: 0,
              width: 150,
              fontSize: 20,
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              textShadow: '0px 0px 4px white,0px 0px 8px white',
            }}
            onClick={() => setDetail(false)}>
            <Icon large icon="cross" style={{ marginRight: 20, transform: 'scale(2)', cursor: 'pointer' }} />
            CLOSE
          </div>
        </div>
      )}
      <Footer />
    </div>
  )
}

export default Product
