import React, { useState } from 'react'
import useFetch from './useFetch'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
import { useNavigate, useLocation, useMatch } from 'react-router-dom'
import Product from './Product'
import styles2 from './App.module.scss'
import config from './config'
// import Swiper core and required modules
import SwiperCore, { EffectFade, Navigation, Pagination, Mousewheel, Thumbs } from 'swiper'

import styles from './App.module.scss'
import interior1 from './images/interior-1.jpg'
import interior2 from './images/interior-2.jpg'
import interior3 from './images/interior-3.jpg'
import interior4 from './images/interior-4.jpg'

SwiperCore.use([Navigation, Pagination, Mousewheel, Thumbs, EffectFade])

const Products = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null)

  const [translate, setTranslate] = useState(undefined)
  const [transition, setTransition] = useState(0.4)

  const { posts, loading } = useFetch(`${config.api}/wp-json/wc/v3/products`)
  const match = useMatch('/interiors')
  let navigate = useNavigate()
  return (
    <div
      className={styles.interior}
      style={{
        position: 'fixed',
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        backgroundColor: 'black',
        transition: 'backgroundColor 1s',
      }}>
      <Swiper
        effect={'fade'}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        slidesPerView={'auto'}
        style={{ overflow: 'visible', position: 'relative', height: '100%', width: 'auto' }}
        loop={true}
        mousewheel={true}
        centeredSlides={true}>
        <SwiperSlide style={{ height: '100%', width: '100%' }}>
          <img src={interior1} className={styles2.interior_bg} />
        </SwiperSlide>
        <SwiperSlide style={{ height: '100%', width: '100%' }}>
          <img src={interior2} className={styles2.interior_bg} />
        </SwiperSlide>
        <SwiperSlide style={{ height: '100%', width: '100%' }}>
          <img src={interior3} className={styles2.interior_bg} />
        </SwiperSlide>
        <SwiperSlide style={{ height: '100%', width: '100%' }}>
          <img src={interior4} className={styles2.interior_bg} />
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default Products
