// process.env contains the config parameters set in the active .env file

const config = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  api: process.env.REACT_APP_API,
  woocommerceKey: process.env.REACT_APP_WOOCOMMERCE_KEY,
  pollingInterval: 30 * 1000,
  landingPage: '/publications',
  nonProductionBanner: process.env.REACT_APP_NON_PRODUCTION_BANNER,
}

export default {
  // Add common config values here
  ...config,
}
