import React from 'react'

const Title = (props) => (
  <>
    <h2 style={{ fontSize: 20 }}>{props.children}</h2>
    <div style={{ width: 60, height: 2, backgroundColor: 'black', marginBottom: 40 }}></div>
  </>
)

export default Title
