import React, { useState } from 'react'
import Next from './Next'
import Title from './Title'
import Footer from './Footer'
const ServiceEducation = () => {
  document.title = 'Determination & Taxation of your Fossils - Willendorf Gallery Services'
  document.querySelector('meta[name="description"]').setAttribute(
    'content',
    `Willendorf Gallery offers free determination and taxation advice to both amateur enthusiasts and specialists. As
    such we have an exclusive collaboration with Arjen Van Horn. Arjen has spent most of his young adult life
    collecting and determining fossils and works closely with a global network of archeologists, paleontologists and
    archeo-zoologists.`
  )
  return (
    <>
      <Title>Determination &amp; Taxation</Title>
      <p>
        Willendorf Gallery offers free determination and taxation advice to both amateur enthusiasts and specialists. As
        such we have an exclusive collaboration with Arjen Van Horn. Arjen has spent most of his young adult life
        collecting and determining fossils and works closely with a global network of archeologists, paleontologists and
        archeo-zoologists.
      </p>
      <p>
        Do you have questions on an item you found and want to know more about it and its value? Don’t hesitate to drop
        us a line, include clear pictures from multiple angles and detailed information such as excavation site,
        measurements, weight, particular features and we’ll get back to you as soon as possible.
      </p>
      <p>
        Get in touch through our <a href="/contact">contact form</a> or send us a mail at{' '}
        <a href="mailto:info@willendorfgallery.com">info@willendorfgallery.com</a>
      </p>
      <Next to="../preservation" text="Preservation &amp; Display" />

      <Footer />
    </>
  )
}

export default ServiceEducation
