import React, { useState } from 'react'
import { Icon } from '@blueprintjs/core'
import Next from './Next'
import { NavLink } from 'react-router-dom'
import Title from './Title'
import Footer from './Footer'
import Education1 from './images/Education 1.jpg'
import Education2 from './images/Education 2.jpg'

const ServiceEducation = () => {
  document.title = 'Educational boxes for schools - Willendorf Gallery Services'
  document.querySelector('meta[name="description"]').setAttribute(
    'content',
    `Willendorf Gallery strives to broaden its artistic and scientific scope by offering educational boxes to schools and non-profit organisations, ensuring that the future generations can enjoy and learn from our common cultural
  heritage. These show-and-tell boxes contain a wide variety of items, ranging from mammoth molars to cave
  bear teeth and early human artefacts such as bone and flint tools. Accompanied by a lesson plan, teachers and
  pupils can relive the past and feel the historical value of these items in their hands. A true addition to any
  history lesson or workshop on life during the Paleolithic and Neolithic era.`
  )

  return (
    <>
      <Title>Education</Title>
      <p>
        Willendorf Gallery strives to broaden its artistic and scientific scope by offering educational boxes to schools
        and non-profit organisations, ensuring that the future generations can enjoy and learn from our common cultural
        heritage. These <i>show-and-tell</i> boxes contain a wide variety of items, ranging from mammoth molars to cave
        bear teeth and early human artefacts such as bone and flint tools. Accompanied by a lesson plan, teachers and
        pupils can relive the past and feel the historical value of these items in their hands. A true addition to any
        history lesson or workshop on life during the Paleolithic and Neolithic era.
      </p>
      <p>
        More information? Get in touch through our <a href="/contact">contact form</a> or send us a mail at{' '}
        <a href="mailto:info@willendorfgallery.com">info@willendorfgallery.com</a>
      </p>

      <Next to="../taxation" text="Determination &amp; Taxation" />
      <img src={Education2} style={{ border: '1px solid black', width: '100%', marginTop: 20 }} />

      <img src={Education1} style={{ border: '1px solid black', width: '100%', marginTop: 20 }} />

      <Footer />
    </>
  )
}

export default ServiceEducation
