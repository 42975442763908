import React, { useState } from 'react'
import { Icon } from '@blueprintjs/core'
import styles2 from './App.module.scss'
import { NavLink } from 'react-router-dom'
import Title from './Title'
import Footer from './Footer'
import Next from './Next'
const ServicePreservation = () => {
  document.title = 'Preservation & Display of your fossil - Willendorf Gallery Services'
  document.querySelector('meta[name="description"]').setAttribute(
    'content',
    `Willendorf Gallery offers tailored solutions to the preservation of any fossil that needs special treatment and
    stabilization.`
  )

  return (
    <>
      <Title>Preservation &amp; Display </Title>
      <p>
        Willendorf Gallery offers tailored solutions to the preservation of any fossil that needs special treatment and
        stabilization.
      </p>
      <p>
        All fossils, but especially those found on the North Sea Beds require a specialist treatment before they are
        ready to be displayed. Arjen Van Horn is our expert in this field, with a specialty in restoring mammoth tusks
        and skulls of larger mammals. Arjen’s dedication to preserve fossils for the future is what sets him apart and
        his expert skill set is unmatched. Need more information?
      </p>
      <p>
        Get in touch through our <a href="/contact">contact form</a> or send us a mail at{' '}
        <a href="mailto:info@willendorfgallery.com">info@willendorfgallery.com</a>
      </p>
      <Next to="../consignment" text="Consign exclusive fossils" />

      <Footer />
    </>
  )
}

export default ServicePreservation
