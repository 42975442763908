import React, { useState } from 'react'
import { Icon } from '@blueprintjs/core'
import { useNavigate, useLocation, useResolvedPath, useMatch } from 'react-router-dom'
import styles from './Menu.module.scss'

import styles2 from './App.module.scss'
import Sidebar from './Sidebar'
const Menu = (props) => {
  const [offset, setOffset] = useState(0)
  const [width, setWidth] = useState(0)

  let navigate = useNavigate()
  const match = useMatch('/:page/*')

  const page = match?.params?.page
  const id = match?.params?.id

  let location = useLocation()
  return (
    <>
      <div className={[styles.menu, !page ? styles.mainPage : undefined].join(' ')}>
        <span onClick={() => navigate('/')} className={styles.title}>
          <div>Willendorf {page && <span className={[styles2.mobile]}>gallery</span>}</div>
          {page && (
            <div style={{ fontSize: 30, marginTop: 5 }} className={styles2.mobile}>
              {page}
            </div>
          )}
          <div className={styles.subtitle}>Gallery</div>
        </span>

        {!props.comingSoon && (
          <ul style={{ display: 'flex', flex: '1 1 0' }} className={styles2.desktop}>
            <li style={{ flex: 1 }} />
            <li
              className={[styles2.desktop, page === 'collection' ? styles.menu_active : ''].join(' ')}
              onClick={() => navigate('/collection/all')}>
              Collection
            </li>
            <li
              className={[styles2.desktop, page === 'gallery' ? styles.menu_active : ''].join(' ')}
              onClick={() => navigate('/gallery/all')}>
              Gallery
            </li>
            {false && (
              <li
                className={[styles2.desktop, page === 'interiors' ? styles.menu_active : ''].join(' ')}
                onClick={() => navigate('/interiors')}>
                Interiors
              </li>
            )}
            <li
              className={[styles2.desktop, page === 'press' ? styles.menu_active : ''].join(' ')}
              onClick={() => navigate('/press')}>
              Press
            </li>
            <li
              className={[styles2.desktop, page === 'services' ? styles.menu_active : ''].join(' ')}
              onClick={() => navigate('/services')}>
              Services
            </li>
            <li
              className={[styles2.desktop, page === 'about' ? styles.menu_active : ''].join(' ')}
              onClick={() => navigate('/about')}>
              About
            </li>
            <li
              className={[styles2.desktop, page === 'contact' ? styles.menu_active : ''].join(' ')}
              onClick={() => navigate('/contact')}>
              Contact
            </li>
          </ul>
        )}
        {!props.comingSoon && (
          <Icon
            className={styles2.mobile}
            onClick={() => props.setIsOpen(!props.open)}
            size={24}
            icon="menu"
            style={{ color: 'white', marginRight: 15, marginTop: 10 }}
          />
        )}
      </div>
    </>
  )
}
export default Menu
