import React, { useState } from 'react'
import Footer from './Footer'
import Next from './Next'
import Title from './Title'
const Terms = () => (
  <div style={{ textAlign: 'justify' }}>
    <Title>Terms &amp; Conditions</Title>
    <h3>Purchase</h3>
    <p>
      When purchasing one or multiple objects at Willendorf Gallery, please provide full name, address and telephone
      number. Always state the object reference code in your communication. Feel free to pose additional questions.
    </p>
    <h3>Payment</h3>
    <p>
      An item is reserved as soon as a 20% deposit is made out. Finalizing the purchase and shipping details might take
      up some time, but the item will be reserved until final payment of the balance. We accept PayPal and bank transfer
      payments. (additional fee PayPal) All payments need to be completed in full, prior to a shipping of the item(s).
    </p>
    <h3>Sales tax</h3>
    <p>
      All domestic clients are obliged to pay the VAT which is 21%. Due to international law, international clients do
      not pay VAT.
    </p>
    <h3>Condition of the item</h3>
    <p>
      Willendorf Gallery takes pride in providing the most detailed pictures and description possible. Whenever an item
      has restorations, these will be elaborately discussed in the description, often with a percentage of restoration.
      That being said, these fossils have seen eons pass, and no object should be considered in “mint condition”. When
      the Gallery describes items as pristine, perfect or nicely preserved one should always bear in mind that a fossil
      is never in mint condition.
    </p>
    <h3> Refund Policy</h3>
    <p>
      We have a full refund policy for all items under €2000, all sales above this amount are final. Refunds have to be
      completed within 10 working days, with the item completely intact. The return shipping is at the buyer’s expense.
      If for any reason you are dissatisfied with your purchase, you may return any unaltered merchandise within 10 days
      of the invoice date for a complete refund less all shipping and handling charges. Within this time frame, no
      merchandise will be accepted without prior return authorization by us which can be obtained by contacting us
      before returning the order. Orders shipped back to us without prior communication and authorization will be
      refused and the sale considered final.
    </p>
    <h3>Customs</h3>
    <p>
      When buying an item, it is the buyer’s responsibility to inform him or herself as to the local, judicial
      restrictions on the import of the fossil in question. Some countries ban or heavily restrict the import of the
      fossils, so any claim concerning problems at customs at the country of export is at the buyer’s expense.
      Willendorf Gallery will cooperate and provide any legal documents concerning the export of the country of origin,
      which is Belgium.
    </p>
    <h3>Damaged Goods &amp; Lost Parcels</h3>
    <p>
      Willendorf Gallery takes pride in using the utmost diligence when it comes to packaging. However, due to
      malpractices of certain shipping companies, we strongly advise to insure all packages for the full amount. In the
      unlikely event of a shipment arriving damaged, it is important to keep all packing material and boxes that the
      item was shipped in and we must be notified immediately of the damage. These materials are needed in order to file
      an insurance claim with the shipping company. Without the original packing material and box, the carrier will not
      allow a claim to be filed and no reimbursement or exchange can be made by the company. A special notification must
      be made for mammoth molars from the North Sea Bed. These molars are trailed by shipping vessels and have been on
      the bottom of of the salty sea bed for thousands of years. This means they need a special kind of treatment,
      stabilizing the inner and outer core of the molar. We keep them generally for over six months to see of the molar
      evolves but in case of temperature and moist differences, these molars are prone to break. In case of air freight,
      we ship them in thermal boxes so as to keep the temperature stable. Nevertheless, even then there is always a
      possibility they will break. For this reason, we cannot refund mammoth molars. We generally use GLS One for all
      European shipping, and DHL express or UPS for international shipping.
    </p>
    <h3>Our Guarantee</h3>
    <p>
      Willendorf Gallery can provide a Certificate of Authenticity for all items in our collection. When provenance is
      unclear, this will be stated clearly, but all our fossils and artefacts are genuine and real.
    </p>
    <Next to="../privacy" text="Privacy Statement" />

    <Footer />
  </div>
)

export default Terms
