import React, { useEffect, useState, lazy, Suspense } from 'react'
import useFetch from './useFetch'
import { Navigate, useNavigate, Route, Routes, useMatch, BrowserRouter as Router } from 'react-router-dom'
import Home from './Home'
import Menu from './Menu'
import About from './About'
import Contact from './Contact'
import Terms from './Terms'
import config from './config'
import Press from './Press'
import Services from './Services'
import styles from './App.module.scss'
import Interiors from './Interiors'
import Gallery from './Gallery'
import Sidebar from './Sidebar'
import 'swiper/css'

import Cloud from './images/cloud.png'

const App = () => {
  const match = useMatch('/:page/*')

  const page = match?.params?.page
  const { data, loading } = useFetch(`${config.api}/wp-json/wc/v3/products?per_page=100`)
  const c = useFetch(`${config.api}/wp-json/wc/v3/products/categories?per_page=100`)
  const categories = c.data
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const comingSoon = false
  return (
    <>
      {!comingSoon && <Sidebar open={open} setIsOpen={setOpen} />}
      <div className={styles.regular} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <div
          style={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            /* z-index: 10000; */
            boxShadow: '0 0 156px 36px inset black',
            pointerEvents: 'none',
            zIndex: 1,
          }}></div>

        <Menu setIsOpen={setOpen} open={open} comingSoon={comingSoon} />
        <div
          className={styles.mainWindow}
          style={{
            display: 'flex',
            flex: 1,

            position: 'relative',
            alignItems: 'center',
            zIndex: page === 'interiors' ? 0 : 1,
          }}>
          <Routes>
            <Route exact path="/" element={<Home comingSoon={comingSoon} />} />
            {false && <Route path="interiors" element={<Interiors />} />}
            <Route path="collection" element={<Navigate to="all" />} />
            <Route path="collection/*" element={<Gallery data={data} categories={categories} />} />
            <Route path="gallery" element={<Navigate to="all" />} />
            <Route path="gallery/*" element={<Gallery data={data} categories={categories} />} />
            <Route path="press/*" element={<Press />} />
            <Route path="about/*" element={<About />} />
            <Route path="services/*" element={<Services />} />
            <Route path="contact" element={<Contact />} />
          </Routes>
        </div>
      </div>
    </>
  )
}

export default App
