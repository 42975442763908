import React, { useState } from 'react'
import styles from './Contact.module.scss'
import styles2 from './App.module.scss'
import Title from './Title'
const Footer = () => (
  <div
    className={styles2.mobile}
    style={{
      width: '100%',
      color: '#ccc',
      fontFamily: 'Tundra',
      disply: 'block',
      padding: 20,
      paddingBottom: 0,

      marginTop: 40,
      textAlign: 'center',
    }}>
    &copy; 2021 - Willendorf Gallery
  </div>
)

export default Footer
