import React, { useState, useRef, useEffect } from 'react'
import Footer from './Footer'
import Title from './Title'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Autoplay } from 'swiper'
import { EffectFade } from 'swiper'

// Styles must use direct files imports
import '../node_modules/swiper/swiper.scss' // core Swiper
import '../node_modules/swiper/modules/pagination/pagination.scss' // Navigation module
import '../node_modules/swiper/modules/navigation/navigation.scss' // Navigation module
import '../node_modules/swiper/modules/effect-fade/effect-fade.scss'

import { NavLink, useNavigate, useLocation, useMatch } from 'react-router-dom'
import { Button, Icon, Spinner } from '@blueprintjs/core'
import Product from './ProductMobile'
import styles from './Products.module.scss'
import styles2 from './App.module.scss'
import Sidebar from './SidebarCollection'
import Masonry from 'react-masonry-css'

import interior1 from './images/interior-1.jpg'
import interior2 from './images/interior-2.jpg'
import interior3 from './images/interior-3.jpg'
import interior4 from './images/interior-4.jpg'
import interior5 from './images/interior-5.jpg'
import interior6 from './images/interior-6.jpg'

function shuffleArray(array) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1))
    var temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
}

const breakpointColumnsObj = {
  default: 3,
  1100: 3,
  700: 2,
  500: 1,
}

const getSize = (name) => {
  const r = new RegExp('-([0-9]{1,4})x([0-9]{1,4})', 'gm')

  const res = r.exec(name)

  return res ? { w: parseInt(res[1]), h: parseInt(res[2]) } : { w: 100, h: 100 }
}

const Gallery = (props) => {
  document.title = "Willendorf Gallery's exclusive collection of Fossils & Artefacts"
  document
    .querySelector('meta[name="description"]')
    .setAttribute('content', `Our collection of Paleolithic and Neolithics Fossils and Artefacts`)

  const match = useMatch('/:collection/:filter/*', { exact: false })
  const ref = useRef()
  const [scroll, setScroll] = useState(0)
  const id = match?.params?.['*']
  let type = match?.params?.collection
  let filter = match?.params?.filter
  useEffect(() => {
    if (ref?.current) ref.current.scrollTop = scroll
  }, [ref.current])

  const navigate = useNavigate()
  const [selected, setSelected] = useState({ children: [] })
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [open, setOpen] = useState(false)
  const { data } = props
  let ddata = data || []
  const c = [...new Set(ddata.map((d) => d.categories.map((c) => c.name)).flat())]

  const parent = props.categories?.filter((d) => d.parent === 0).sort((a, b) => a.id - b.id) || []
  const r = parent
    .map((c) => [c, ...props.categories.filter((d) => d.parent === c.id)].sort((a, b) => a.id - b.id))
    .flat()
    .filter((d) => d.slug.toLowerCase() !== 'archive')
  const categories = r

  if (filter !== 'all') ddata = ddata.filter((d) => d.categories.map((d) => d.slug.toLowerCase()).includes(filter))
  ddata = ddata.filter((d) => d.catalog_visibility !== 'hidden')

  if (type === 'gallery') ddata = ddata.filter((d) => d.categories.map((d) => d.slug.toLowerCase()).includes('archive'))
  if (type === 'collection')
    ddata = ddata.filter((d) => !d.categories.map((d) => d.slug.toLowerCase()).includes('archive'))
  console.log(ddata)
  ddata.sort((a, b) => {
    if (a.menu_order > b.menu_order) return -1
    if (a.menu_order < b.menu_order) return 1
    return new Date(a.date_created) > new Date(b.date_created)
  })

  const product = ddata?.find((d) => d.id === parseInt(id))
  const productIndex = ddata?.findIndex((d) => d.id === parseInt(id))
  let skeleton = []
  if (ddata?.length === 0) skeleton = [1, 1.1, 1, 1.2, 1, 1.1, 1, 1.2, 1, 1.1, 1, 1.3]
  let prevIndex = null
  let nextIndex = null
  if (ddata?.length === 1) {
    nextIndex = null
    prevIndex = null
  } else if (ddata?.length === 2) {
    if (productIndex === 0) {
      nextIndex = 1
      prevIndex = null
    }
    if (productIndex === 1) {
      nextIndex = null
      prevIndex = 0
    }
  } else {
    nextIndex = productIndex < ddata.length - 1 ? productIndex + 1 : 0
    prevIndex = productIndex > 0 ? productIndex - 1 : ddata.length - 1
  }

  const parentTitle = categories.find((d) => d.id === categories.find((d) => d.slug.toLowerCase() === filter)?.parent)

  if (product)
    return (
      <Product
        filter={filter}
        type={type}
        data={product}
        prev={prevIndex !== null ? ddata[prevIndex] : null}
        next={nextIndex !== null ? ddata[nextIndex] : null}
      />
    )
  return (
    <>
      <div
        className={styles2.bg}
        ref={ref}
        onScroll={(e) => setScroll(e.target.scrollTop)}
        style={{ display: 'flex', width: '100%' }}>
        <div className={styles2.mobile} style={{ width: '100%', margin: 20, marginBottom: 0, display: 'flex' }}>
          <Sidebar categories={categories} open={open} setIsOpen={setOpen} />
          <Title>
            {parentTitle && (
              <>
                {parentTitle?.name}
                <Icon
                  icon="chevron-right"
                  style={{ marginLeft: 10, marginRight: 10, marginBottom: 3, color: '#aaa' }}
                />
              </>
            )}
            {filter}
            {type === 'collection' && (
              <Button
                minimal
                icon="filter"
                onClick={() => setOpen(true)}
                style={{ float: 'right', position: 'relative', marginBottom: 3 }}
              />
            )}
          </Title>
        </div>
        {filter === 'all' && type === 'collection' && (
          <div
            className={styles2.desktop}
            style={{
              flex: 1,
              width: '70%',
              paddingLeft: 15,
              paddingRight: 15,
              maxWidth: 900,
            }}>
            <Swiper
              modules={[Navigation, Pagination, EffectFade, Autoplay]}
              effect={'fade'}
              autoplay={{ delay: 3000 }}
              speed={2000}
              navigation={true}
              pagination={{
                clickable: true,
              }}
              style={{ overflow: 'hidden', position: 'relative', width: '100%' }}
              loop={true}
              mousewheel={true}
              centeredSlides={true}>
              <SwiperSlide style={{ width: '100%' }}>
                <img src={interior1} style={{ width: '100%', border: '1px solid black' }} />
              </SwiperSlide>
              <SwiperSlide style={{ width: '100%' }}>
                <img src={interior2} style={{ width: '100%', border: '1px solid black' }} />
              </SwiperSlide>
              <SwiperSlide style={{ width: '100%' }}>
                <img src={interior3} style={{ width: '100%', border: '1px solid black' }} />
              </SwiperSlide>
              <SwiperSlide style={{ width: '100%' }}>
                <img src={interior4} style={{ width: '100%', border: '1px solid black' }} />
              </SwiperSlide>
              <SwiperSlide style={{ width: '100%' }}>
                <img src={interior5} style={{ width: '100%', border: '1px solid black' }} />
              </SwiperSlide>
              <SwiperSlide style={{ width: '100%' }}>
                <img src={interior6} style={{ width: '100%', border: '1px solid black' }} />
              </SwiperSlide>
            </Swiper>
          </div>
        )}
        <div
          className={filter === 'all' && type === 'collection' ? styles2.mobile : styles.hidden}
          style={{
            flex: 1,
            width: '70%',
            paddingLeft: 15,
            paddingRight: 15,
          }}>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className={styles['my-masonry-grid']}
            columnClassName={styles['my-masonry-grid_column']}>
            {skeleton?.map((p, i) => (
              <div
                key={i}
                className="bp3-skeleton"
                style={{ width: '100%', display: 'inline-block', aspectRatio: p + '' }}
              />
            ))}
            {ddata?.map((post, index) => (
              <div
                key={index}
                className={styles.product}
                style={{ width: '100%' }}
                onClick={() => {
                  navigate(`/${type}/${filter}/${post.id}`)
                }}>
                {post.images[0]?.src && (
                  <img
                    src={post.images[0].woocommerce_single}
                    width={getSize(post.images[0].woocommerce_single).w}
                    height={getSize(post.images[0].woocommerce_single).h}
                    style={{ width: '100%', height: 'auto' }}
                  />
                )}
                <div className={styles.corner_bottomleft} />
                <div className={styles.corner_topright} />
                <div className={styles.product_description}>
                  <h3>{post.name}</h3>
                  <h3 style={{ width: '40%', height: 1, margin: 5, borderBottom: '1px solid white' }} />
                  <h3 style={{ fontSize: 11 }}>{post.meta_data.find((d) => d.key === 'age')?.value}</h3>
                </div>
              </div>
            ))}
          </Masonry>
        </div>

        <div style={{ width: 200, position: 'sticky', marginLeft: 50 }} className={styles2.desktop}>
          <Title>{type}</Title>
          {type === 'gallery' && (
            <p>This gallery has the sole purpose of showcasing past sales, to be enjoyed by everyone.</p>
          )}
          {type === 'collection' &&
            categories.map((d) => (
              <NavLink
                key={d.slug}
                style={{ pointerEvents: d.count === 0 ? 'none' : 'undefined' }}
                to={`/${type}/${d.slug.toLowerCase()}`}
                activeClassName={styles2.activeLink}
                className={styles2.link}>
                {d.parent > 0 && <>&nbsp;&nbsp;&nbsp;&nbsp;</>}
                {d.name}
              </NavLink>
            ))}
        </div>
      </div>
    </>
  )
}

export default Gallery
