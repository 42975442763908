import React, { useState } from 'react'
import useFetch from './useFetch'
import styles from './Contact.module.scss'
import styles2 from './App.module.scss'
import { Navigate, useNavigate, NavLink, Routes, Route, useMatch, BrowserRouter as Router } from 'react-router-dom'
import Title from './Title'
import ServiceEducation from './ServiceEducation'
import ServiceTaxation from './ServiceTaxation'
import ServicePreservation from './ServicePreservation'
import ServiceConsignment from './ServiceConsignment'
import config from './config'
const Press = () => {
  document.title = 'Willendorf Gallery - Our Services'
  document
    .querySelector('meta[name="description"]')
    .setAttribute(
      'content',
      `Willendorf Gallery additional services including Eduction, Determination and Taxation, Preservation and Consignment.`
    )
  const match = useMatch('/press/:id/*')

  const id = match?.params?.id

  const { data, loading } = useFetch(`${config.api}/wp-json/wp/v2/posts`)

  const currentIndex = id ? data?.findIndex((post) => post.date === id) : undefined
  const nextIndex = currentIndex < data?.length - 1 ? currentIndex + 1 : undefined
  const prevIndex = currentIndex > 0 ? currentIndex - 1 : undefined

  const next = nextIndex >= 0 ? data[nextIndex] : undefined
  const prev = prevIndex >= 0 ? data[prevIndex] : undefined

  const first = id ? data?.find((post) => post.date === id) : data?.[0]
  return (
    <div className={styles2.wrapper}>
      <div className={styles2.columns}>
        <span>
          <Title>{first?.title.rendered}</Title>
          <div dangerouslySetInnerHTML={{ __html: first?.content.rendered }} />
          <div
            style={{
              width: '100%',
              marginTop: 30,
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            {prev && (
              <span>
                <NavLink to={`/press/${prev.date}`} className={styles2.link}>
                  <span style={{ fontSize: '2em', marginRight: 10 }}>&larr;</span>
                  Previous post
                </NavLink>
              </span>
            )}
            <span style={{ flex: 1 }} />
            {next && (
              <span>
                <NavLink to={`/press/${next.date}`} className={styles2.link} >
                  Next post<span style={{ fontSize: '2em', marginLeft: 10 }}>&rarr;</span>
                </NavLink>
              </span>
            )}
          </div>
        </span>
        <span style={{ textAlign: 'left' }} className={styles2.desktop}>
          <Title>Previous Posts</Title>

          {data?.map((post, i) => (
            <NavLink key={i} to={`/press/${post.date}`} activeClassName={styles2.activeLink} className={styles2.link}>
              {post.title.rendered}
            </NavLink>
          ))}
        </span>
      </div>
    </div>
  )
}

export default Press
