import React, { useState } from 'react'
import Footer from './Footer'
import Next from './Next'
import Title from './Title'
const Terms = () => (
  <div>
    <Title>Privacy Statement</Title>
    <p>Willendorf Gallery upholds the safest protection of your privacy</p>
    <h3>SECTION 1 – PURPOSE OF YOUR INFORMATION</h3>
    <p>
      When purchasing an item at our gallery we gather your personal information such as your name, address and email
      address, as part of the standard buying and selling process. This information will not be shared with any other
      company, gallery or natural person. We never share, sell, exchange, or otherwise give away your data. We do not
      offer 3rd party services or products. This privacy policy applies to all information that would be collected
      through our website. It will not be used as part of a marketing or sales strategy. You may at any time may opt for
      consignment to our newsletter.
    </p>
    <h3>SECTION 2 - CONSENT</h3>
    <p>
      When you provide us with personal information to complete a transaction, verify your credit card, place an order,
      arrange for a delivery or return a purchase, we imply that you consent to our collecting it and using it for that
      specific reason only.
    </p>
    <h3>SECTION 3 - DISCLOSURE</h3>
    <p>
      We may disclose your personal information if we are required by law to do so or if you violate our Terms of Sale.
    </p>
    <h3>SECTION 4 - HOST</h3>
    <p>
      Our gallery is hosted by ANTARES. They provide us with the online e-commerce platform that allows us to sell our
      products and services to you. Your data is stored in European datacenters on secure firewalled servers.
    </p>
    <h3>SECTION 5 - SECURITY</h3>
    <p>
      To protect your personal information, we take reasonable precautions and follow industry best practices to make
      sure it is not inappropriately lost, misused, accessed, disclosed, altered or destroyed.
    </p>
    <h3>SECTION 6 - AGE OF CONSENT</h3>
    <p>
      By using this site, you represent that you are at least the age of majority in your country, or that you are the
      age of majority in country and you have given us your consent to allow any of your minor dependents to use this
      site.
    </p>
    <h3>SECTION 7 - CHANGES TO THE PRIVACY STATEMENT</h3>
    <p>
      Willendorf Gallery reserves the right to modify this privacy statement at any time, so please review it
      frequently. Changes and clarifications will take effect immediately upon their posting on the website. If we make
      material changes to this policy, we will notify you here that it has been updated, so that you are aware of what
      information we collect, how we use it, and under what circumstances, if any, we use and disclose it.
    </p>
    <p>
      Further questions? Get in touch through our <a href="/contact">contact form</a> or send us a mail at
      <a href="mailto:info@willendorfgallery.com">info@willendorfgallery.com</a>
    </p>
    <Next to="../terms" text="Terms &amp; Conditions" />
    <Footer />
  </div>
)

export default Terms
