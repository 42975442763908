import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import styles2 from './App.module.scss'
import { Icon } from '@blueprintjs/core'
const Next = (props) => (
  <span style={{ textAlign: 'right', width: '100%', marginTop: 20 }} className={styles2.mobile}>
    <NavLink to={props.to} activeClassName={styles2.activeLink} className={styles2.link}>
      <Icon icon="arrow-right" style={{ marginRight: 10, width: 'unset' }} />
      next: {props.text}
    </NavLink>
  </span>
)

export default Next
