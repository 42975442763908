import React, { useState } from 'react'
import styles from './Contact.module.scss'
import styles2 from './App.module.scss'
import { Navigate, useNavigate, NavLink, Routes, Route, BrowserRouter as Router } from 'react-router-dom'
import Title from './Title'
import ServiceEducation from './ServiceEducation'
import ServiceTaxation from './ServiceTaxation'
import ServicePreservation from './ServicePreservation'
import ServiceConsignment from './ServiceConsignment'

const About = () => {
  document.title = 'Willendorf Gallery - Our Services'
  document
    .querySelector('meta[name="description"]')
    .setAttribute(
      'content',
      `Willendorf Gallery additional services including Eduction, Determination and Taxation, Preservation and Consignment.`
    )

  return (
    <div className={styles2.wrapper}>
      <div className={styles2.columns}>
        <span>
          <Routes>
            <Route exact path="" element={<Navigate to="education" />}></Route>
            <Route path="education" element={<ServiceEducation />} />
            <Route path="taxation" element={<ServiceTaxation />} />
            <Route path="preservation" element={<ServicePreservation />} />
            <Route path="consignment" element={<ServiceConsignment />} />
          </Routes>
        </span>
        <span style={{ textAlign: 'left' }} className={styles2.desktop}>
          <Title>Services</Title>
          <NavLink to="education" activeClassName={styles2.activeLink} className={styles2.link}>
            Education
          </NavLink>
          <NavLink to="taxation" activeClassName={styles2.activeLink} className={styles2.link}>
            Determination &amp; Taxation
          </NavLink>
          <NavLink to="preservation" activeClassName={styles2.activeLink} className={styles2.link}>
            Preservation &amp; Display
          </NavLink>
          <NavLink to="consignment" activeClassName={styles2.activeLink} className={styles2.link}>
            Consign exclusive fossils
          </NavLink>
        </span>
      </div>
    </div>
  )
}

export default About
